var eziDebit = function () {
    var endpoint;
    var version = "2.0.0";
    var settings = {};
    var publicKey;
    var fields = {};
    var validated = "";

    setValidated = function (valid) {
        validated = valid;
    };

    // Prepare
    init = function (key, options, endpointURL) {
        publicKey = key;
        endpoint = endpointURL;

        if (options) {
            mergeFields(settings, options);
            initialiseFields();
            initialiseButton();
        }
    },

        initialiseFields = function () {

            // Customer details
            if ('customerLastName' in settings) {
                fields.customerLastName = getElement(settings.customerLastName);
            }

            if ('customerRef' in settings) {
                fields.customerRef = getElement(settings.customerRef);
            }

            if ('customerFirstName' in settings) {
                fields.customerFirstName = getElement(settings.customerFirstName);
            }

            if ('customerAddress1' in settings) {
                fields.customerAddress1 = getElement(settings.customerAddress1);
            }

            if ('customerMobile' in settings) {
                fields.customerMobile = getElement(settings.customerMobile);
            }

            if ('customerAddress2' in settings) {
                fields.customerAddress2 = getElement(settings.customerAddress2);
            }

            if ('customerSuburb' in settings) {
                fields.customerSuburb = getElement(settings.customerSuburb);
            }

            if ('customerState' in settings) {
                fields.customerState = getElement(settings.customerState);
            }

            if ('customerPostcode' in settings) {
                fields.customerPostcode = getElement(settings.customerPostcode);
            }

            if ('customerEmail' in settings) {
                fields.customerEmail = getElement(settings.customerEmail);
            }

            if ('customerReference' in settings) {
                fields.customerReference = getElement(settings.customerReference);
            }

            // Card details
            if ('nameOnCard' in settings) {
                fields.nameOnCard = getElement(settings.nameOnCard);
                fields.nameOnCard.removeAttribute("name");
            }

            if ('cardNumber' in settings) {
                fields.cardNumber = getElement(settings.cardNumber);
                fields.cardNumber.removeAttribute('name');
            }

            if ('cardExpiryMonth' in settings) {
                fields.cardExpiryMonth = getElement(settings.cardExpiryMonth);
                fields.cardExpiryMonth.removeAttribute("name");
            }

            if ('cardExpiryYear' in settings) {
                fields.cardExpiryYear = getElement(settings.cardExpiryYear);
                fields.cardExpiryYear.removeAttribute("name");
            }

            if ('cardCCV' in settings) {
                fields.cardCCV = getElement(settings.cardCCV);
                fields.cardCCV.removeAttribute("name");
            }

            if ('paymentAmount' in settings) {
                fields.paymentAmount = getElement(settings.paymentAmount);
            }

            if ('paymentReference' in settings) {
                fields.paymentReference = getElement(settings.paymentReference);
            }

            // Account details
            if ('accountName' in settings) {
                fields.accountName = getElement(settings.accountName);
            }

            if ('accountBSB' in settings) {
                fields.accountBSB = getElement(settings.accountBSB);
            }

            if ('accountNumber' in settings) {
                fields.accountNumber = getElement(settings.accountNumber);
            }

            // Button
            if ('submitButton' in settings) {
                fields.submitButton = getElement(settings.submitButton);
            }

            if ('submitAction' in settings) {
                switch (settings.submitAction) {
                    case "ChargeCard":
                        // No need to display the customer information
                        if ('customerDetailsDiv' in settings) {
                            var customerDetails = document.getElementById(settings.customerDetailsDiv);
                            customerDetails.style.display = "none";
                        }
                        break;
                }
            }
        },

        getElement = function (value) {
            if (typeof value == "string" || value instanceof String)
                return document.getElementById(value);
            else
                return value;
        },

        mergeFields = function (root) {
            for (var i = 1; i < arguments.length; i++)
                for (var key in arguments[i])
                    root[key] = arguments[i][key];
            return root;
        },

        initialiseButton = function () {
            if (fields.submitButton) {
                fields.submitButton.onclick = function () {
                    fields.submitButton.setAttribute("disabled", "disabled");
                    processTransaction(settings.submitAction);
                    return false;
                };
            }
        },

        processTransaction = function (submitAction) {
            if (!publicKey) {
                eziDebitErrorMessages.generateError("No public key has been specified", settings.submitError);
                return;
            }

            JSONP.get(endpoint + "/TestPublicKey",
                {
                    PublicKey: publicKey
                },
                function (response) {
                    if (!response || response.Error > 0) {
                        eziDebitErrorMessages.generateError("No valid public key has been specified", settings.submitError);
                    }
                    else {
                        eziDebitValidation.validate(submitAction, settings)

                        if (validated == true) {

                            switch (submitAction) {
                                case "ChargeCard":
                                    chargeCard();
                                    break;
                                case "SaveCustomer":
                                    saveCustomer();
                                    break;
                                case "SaveCustomerAccount":
                                    saveCustomerAccount();
                                    break;
                                case "ChangeCustomerPaymentInfo":
                                    changeCustomerPaymentInfo();
                                    break;
                            }

                        }
                        else {
                            fields.submitButton.removeAttribute("disabled");
                            return false;
                        }
                    }
                }, jsonFailure);
        },

        jsonFailure = function () {
            eziDebitErrorMessages.generateError("An error has occurred attempting to contact the API. Please contact Ezidebit support.", settings.submitError);
        },

        // Public function, can call ChargeCard directly if required
        chargeCard = function (ccSettings) {

            if (ccSettings !== undefined) {

                settings = ccSettings;
                settings.submitAction = "ChargeCard";
                initialiseFields();
                eziDebitValidation.validate("ChargeCard", settings);

                if (validated == false) {
                    return false;
                }
            }

            JSONP.get(endpoint + "/ChargeCard",
                {
                    PublicKey: publicKey,
                    CreditCardExpiryMonth: fields.cardExpiryMonth.value,
                    CreditCardNumber: fields.cardNumber.value,
                    CreditCardExpiryYear: fields.cardExpiryYear.value,
                    CreditCardCCV: fields.cardCCV.value,
                    NameOnCreditCard: fields.nameOnCard.value,
                    PaymentAmountInCents: (Math.round(fields.paymentAmount.value * 100)), // Assume payment field is in dollars
                    CustomerName: fields.nameOnCard.value,
                    PaymentReference: fields.paymentReference.value,
                    ReferrerURL: location.href,
                    UserAgent: navigator.userAgent,
                    Version: version
                },
                function (response) {
                    if (!response || response.Error > 0) {
                        if ('submitButton' in fields) {
                            fields.submitButton.removeAttribute("disabled");
                        }

                        eziDebitErrorMessages.generateError("Ezidebit API Error (" + response.Error + "): " + response.ErrorMessage, settings.submitError);
                    }
                    else if (settings.submitCallback) {
                        if (fields.exchangePaymentID)
                            fields.exchangePaymentID.value = response.Data.ExchangePaymentID;

                        if (response.Data.PaymentResult == "F" && fields.submitButton) {
                            fields.submitButton.removeAttribute("disabled");
                        }

                        settings.submitCallback(response.Data);
                    }
                }, jsonFailure);
        },

        changeCustomerPaymentInfo = function (accountSettings) {
            if (accountSettings !== undefined) {
                settings = accountSettings;
                settings.submitAction = "ChangeCustomerPaymentInfo";
                initialiseFields();
                eziDebitValidation.validate("ChangeCustomerPaymentInfo", settings);

                if (validated == false) {
                    return false;
                }
            }

            JSONP.get(endpoint + "/ChangeCustomerPaymentInfo",
                {
                    PublicKey: publicKey,
                    EzidebitCustomerRef: fields.customerRef ? fields.customerRef.value : "",
                    YourSystemReference: fields.customerReference ? fields.customerReference.value : "",
                    CreditCardNumber: fields.cardNumber ? fields.cardNumber.value : "",
                    CreditCardExpiryMonth: fields.cardExpiryMonth ? fields.cardExpiryMonth.value : "",
                    CreditCardExpiryYear: fields.cardExpiryYear ? fields.cardExpiryYear.value : "",
                    NameOnCreditCard: fields.nameOnCard ? fields.nameOnCard.value : "",
                    AccountName: fields.accountName ? fields.accountName.value : "",
                    AccountBSB: fields.accountBSB ? fields.accountBSB.value : "",
                    AccountNumber: fields.accountNumber ? fields.accountNumber.value : ""
                },
                function (response) {

                    if ('submitButton' in fields) {
                        fields.submitButton.removeAttribute("disabled");
                    }

                    if (!response || response.Error > 0) {
                        eziDebitErrorMessages.generateError("Ezidebit API Error (" + response.Error + "): " + response.ErrorMessage, settings.submitError);
                    }
                    else if (settings.submitCallback) {
                        settings.submitCallback(response);
                    }
                }, jsonFailure);
        },

        // Public function, allows a customer to be saved together with account information
        saveCustomerAccount = function (accountSettings) {
            if (accountSettings !== undefined) {
                settings = accountSettings;
                settings.submitAction = "SaveCustomerAccount";
                initialiseFields();
                eziDebitValidation.validate("SaveCustomerAccount", settings);

                if (validated == false) {
                    return false;
                }
            }

            JSONP.get(endpoint + "/AddCustomer",
                {
                    PublicKey: publicKey,
                    YourSystemReference: fields.customerReference ? fields.customerReference.value : "",
                    YourGeneralReference: "",
                    LastName: fields.customerLastName.value,
                    FirstName: fields.customerFirstName ? fields.customerFirstName.value : "",
                    AddressLine1: fields.customerAddress1 ? fields.customerAddress1.value : "",
                    AddressLine2: fields.customerAddress2 ? fields.customerAddress2.value : "",
                    AddressSuburb: fields.customerSuburb ? fields.customerSuburb.value : "",
                    AddressState: fields.customerState ? fields.customerState.value : "",
                    AddressPostCode: fields.customerPostcode ? fields.customerPostcode.value : "",
                    EmailAddress: fields.customerEmail ? fields.customerEmail.value : "",
                    MobilePhoneNumber: fields.customerMobile ? fields.customerMobile.value : "",
                    ContractStartDate: new Date().toDateString(),
                    SmsPaymentReminder: "N",
                    SmsFailedNotification: "N",
                    SmsExpiredCard: "N",
                    Username: "",
                    CreditCardNumber: "",
                    CreditCardExpiryMonth: "",
                    CreditCardExpiryYear: "",
                    CreditCardCCV: "",
                    NameOnCreditCard: "",
                    PaymentAmount: "0",
                    PaymentReference: fields.paymentReference ? fields.paymentReference.value : "",
                    AccountName: fields.accountName ? fields.accountName.value : "",
                    AccountBSB: fields.accountBSB ? fields.accountBSB.value : "",
                    AccountNumber: fields.accountNumber ? fields.accountNumber.value : "",
                    ReferrerURL: location.href,
                    UserAgent: navigator.userAgent,
                    Version: version
                },
                function (response) {

                    if ('submitButton' in fields) {
                        fields.submitButton.removeAttribute("disabled");
                    }

                    if (!response || response.Error > 0) {
                        eziDebitErrorMessages.generateError("Ezidebit API Error (" + response.Error + "): " + response.ErrorMessage, settings.submitError);
                    }
                    else if (settings.submitCallback) {
                        settings.submitCallback(response);
                    }
                }, jsonFailure);
        },

        // Public function, can call SaveCustomer directly if required
        saveCustomer = function (cusSettings) {

            var cusBSB = '';

            if (cusSettings !== undefined) {

                settings = cusSettings;
                settings.submitAction = "SaveCustomer";
                initialiseFields();
                eziDebitValidation.validate("SaveCustomer", settings);

                if (validated == false) {
                    return false;
                }
            }

            JSONP.get(endpoint + "/AddCustomer",
                {
                    PublicKey: publicKey,
                    YourSystemReference: fields.customerReference ? fields.customerReference.value : "",
                    YourGeneralReference: "",
                    LastName: fields.customerLastName.value,
                    FirstName: fields.customerFirstName ? fields.customerFirstName.value : "",
                    AddressLine1: fields.customerAddress1 ? fields.customerAddress1.value : "",
                    AddressLine2: fields.customerAddress2 ? fields.customerAddress2.value : "",
                    AddressSuburb: fields.customerSuburb ? fields.customerSuburb.value : "",
                    AddressState: fields.customerState ? fields.customerState.value : "",
                    AddressPostCode: fields.customerPostcode ? fields.customerPostcode.value : "",
                    EmailAddress: fields.customerEmail ? fields.customerEmail.value : "",
                    MobilePhoneNumber: fields.customerMobile ? fields.customerMobile.value : "",
                    ContractStartDate: new Date().toDateString(),
                    SmsPaymentReminder: "N",
                    SmsFailedNotification: "N",
                    SmsExpiredCard: "N",
                    Username: "",
                    CreditCardNumber: fields.cardNumber ? fields.cardNumber.value : "",
                    CreditCardExpiryMonth: fields.cardExpiryMonth ? fields.cardExpiryMonth.value : "",
                    CreditCardExpiryYear: fields.cardExpiryYear ? fields.cardExpiryYear.value : "",
                    CreditCardCCV: fields.cardCCV ? fields.cardCCV.value : "",
                    NameOnCreditCard: fields.nameOnCard ? fields.nameOnCard.value : "",
                    PaymentAmount: fields.paymentAmount ? (Math.round(fields.paymentAmount.value * 100)) : "0",
                    PaymentReference: fields.paymentReference ? fields.paymentReference.value : "",
                    AccountName: fields.accountName ? fields.accountName.value : "",
                    AccountBSB: fields.accountBSB ? fields.accountBSB.value : "",
                    AccountNumber: fields.accountNumber ? fields.accountNumber.value : "",
                    ReferrerURL: location.href,
                    UserAgent: navigator.userAgent,
                    Version: version
                },
                function (response) {

                    if ('submitButton' in fields) {
                        fields.submitButton.removeAttribute("disabled");
                    }

                    if (!response || response.Error > 0) {
                        eziDebitErrorMessages.generateError("Ezidebit API Error (" + response.Error + "): " + response.ErrorMessage, settings.submitError);
                    }
                    else if (settings.submitCallback) {
                        settings.submitCallback(response);
                    }
                }, jsonFailure);

        };

    return {
        init: init,
        getElement: getElement,
        isValidated: validated,
        set: setValidated,
        chargeCard: chargeCard,
        saveCustomer: saveCustomer,
        saveCustomerAccount: saveCustomerAccount,
        changeCustomerPaymentInfo: changeCustomerPaymentInfo
    };

} ();

// Error messages
var eziDebitErrorMessages = function () {

    genError = function (err, callback, element) {
        callback(err, element);
    };

    return {
        generateError: genError
    };
} ();

// Perform validation before submitting
var eziDebitValidation = function () {

    performValidation = function (chargeType, settings) {

        switch (chargeType) {

            case "SaveCustomerAccount":

                if (validateAccountDetails(settings)) {
                    eziDebit.set(true);
                }
                else {
                    eziDebit.set(false);
                    return false;
                }

                break;

            case "ChargeCard":

                if (validateCardDetails(settings, true)) {
                    eziDebit.set(true);
                }
                else {
                    eziDebit.set(false);
                    return false;
                }
                break;

            case "SaveCustomer":
                // Customer last name (required)
                if ('customerLastName' in settings) {
                    var customerLastName = eziDebit.getElement(settings.customerLastName).value;
                    if (customerLastName.length < 1) {
                        eziDebitErrorMessages.generateError("No customer last name entered", settings.submitError, settings.customerLastName);
                        eziDebit.set(false);
                        return false;
                    }
                }
                else {
                    eziDebitErrorMessages.generateError("Customer last name field not found", settings.submitError);
                    eziDebit.set(false);
                    return false;
                }

                // Customer postcode (required)
                if ('customerPostcode' in settings) {
                    var customerPostcode = eziDebit.getElement(settings.customerPostcode).value;

                    if (customerPostcode.length > 0 && customerPostcode.length != 4) {
                        eziDebitErrorMessages.generateError("Invalid postcode entered", settings.submitError, settings.customerPostcode);
                        eziDebit.set(false);
                        return false;
                    }
                }

                if ('nameOnCard' in settings) {

                    if (eziDebit.getElement(settings.cardNumber).value != ""
                        || eziDebit.getElement(settings.cardExpiryMonth).value != ""
                        || eziDebit.getElement(settings.cardExpiryYear).value != ""
                        || eziDebit.getElement(settings.nameOnCard).value != "") {

                        if (validateCardDetails(settings, false)) {
                            eziDebit.set(true);
                        }
                        else {
                            eziDebit.set(false);
                            return false;
                        }
                    }
                }

                if ('accountName' in settings) {

                    if (eziDebit.getElement(settings.accountName).value != ""
                        || eziDebit.getElement(settings.accountBSB).value != ""
                        || eziDebit.getElement(settings.accountNumber).value != "") {

                        if (validateAccountDetails(settings)) {
                            eziDebit.set(true);
                        }
                        else {
                            eziDebit.set(false);
                            return false;
                        }
                    }
                }

                break;

            case "ChangeCustomerPaymentInfo":

                if ('customerRef' in settings && 'customerReference' in settings) {
                    if (eziDebit.getElement(settings.customerRef).value == "" && eziDebit.getElement(settings.customerReference).value == "") {
                        eziDebitErrorMessages.generateError("Either customerRef field or customerReference field must have a value specified", settings.submitError, settings.customerRef);
                        return false;
                    }
                }
                else if (!('customerRef' in settings || 'customerReference' in settings)) {
                    eziDebitErrorMessages.generateError("Either customerRef field or customerReference field is required", settings.submitError, settings.customerRef);
                    return false;
                }
                else if ('customerRef' in settings) {
                    if (eziDebit.getElement(settings.customerRef).value == "") {
                        eziDebitErrorMessages.generateError("The customerRef must have a value specified", settings.submitError, settings.customerRef);
                        return false;
                    }
                }
                else if ('customerReference' in settings) {
                    if (eziDebit.getElement(settings.customerReference).value == "") {
                        eziDebitErrorMessages.generateError("The customerReference must have a value specified", settings.submitError, settings.customerRef);
                        return false;
                    }
                }

                if ('accountNumber' in settings) {

                    if (eziDebit.getElement(settings.accountName).value != ""
                        || eziDebit.getElement(settings.accountBSB).value != ""
                        || eziDebit.getElement(settings.accountNumber).value != "") {

                        if (validateAccountDetails(settings)) {
                            eziDebit.set(true);
                        }
                        else {
                            eziDebit.set(false);
                            return false;
                        }
                    }
                    else {
                        eziDebitErrorMessages.generateError("You have not supplied bank account details", settings.submitError);
                        return false;
                    }
                }

                if ('cardNumber' in settings) {

                    if (eziDebit.getElement(settings.cardNumber).value != ""
                        || eziDebit.getElement(settings.cardExpiryMonth).value != ""
                        || eziDebit.getElement(settings.cardExpiryYear).value != ""
                        || eziDebit.getElement(settings.nameOnCard).value != "") {

                        if (validateCardDetails(settings, false)) {
                            eziDebit.set(true);
                        }
                        else {
                            eziDebit.set(false);
                            return false;
                        }
                    }
                    else {
                        eziDebitErrorMessages.generateError("You have not supplied credit card details", settings.submitError);
                        return false;
                    }
                }
                break;
        }
    },

        validateAccountDetails = function (settings) {
            // Bank account name
            if ('accountName' in settings) {
                var accountName = eziDebit.getElement(settings.accountName).value;

                if (accountName.length < 2 || accountName.length > 50) {
                    eziDebitErrorMessages.generateError("The account name is invalid", settings.submitError, settings.accountName);
                    eziDebit.set(false);
                    return false;
                }
            }
            else {
                eziDebitErrorMessages.generateError("Account name field not found", settings.submitError);
                eziDebit.set(false);
                return false;
            }

            // Bank account BSB
            if ('accountBSB' in settings) {
                var accountBSB = eziDebit.getElement(settings.accountBSB).value;

                if (accountBSB.length < 6) {
                    eziDebitErrorMessages.generateError("The BSB should be 6 digits", settings.submitError, settings.accountBSB);
                    eziDebit.set(false);
                    return false;
                }
            }
            else {
                eziDebitErrorMessages.generateError("BSB field not found", settings.submitError);
                eziDebit.set(false);
                return false;
            }

            // Bank account number
            if ('accountNumber' in settings) {
                var accountNumber = eziDebit.getElement(settings.accountNumber).value;

                if (accountNumber.length < 2) {
                    eziDebitErrorMessages.generateError("The account number is invalid", settings.submitError, settings.accountNumber);
                    eziDebit.set(false);
                    return false;
                }
            }
            else {
                eziDebitErrorMessages.generateError("Account number field not found", settings.submitError);
                eziDebit.set(false);
                return false;
            }

            return true;
        },

        validateCardDetails = function (settings, mustCharge) {
            // Customer name
            if ('nameOnCard' in settings) {
                var nameOnCard = eziDebit.getElement(settings.nameOnCard).value;

                if (nameOnCard.length < 3 || nameOnCard.length > 100) {
                    eziDebitErrorMessages.generateError("The name on the card appears to be invalid", settings.submitError, settings.nameOnCard);
                    eziDebit.set(false);
                    return false;
                }
            }
            else {
                eziDebitErrorMessages.generateError("Name on card field not found", settings.submitError);
                eziDebit.set(false);
                return false;
            }

            // Card number
            if ('cardNumber' in settings) {
                var cardNumber = eziDebit.getElement(settings.cardNumber).value;

                // Validate the length of the card to be between 14 and 16 digits
                if (cardNumber.length < 14) {
                    eziDebitErrorMessages.generateError("The card number should be between 14 and 16 digits", settings.submitError, settings.cardNumber);
                    eziDebit.set(false);
                    return false;
                }
            }
            else {
                eziDebitErrorMessages.generateError("Card number field not found", settings.submitError);
                eziDebit.set(false);
                return false;
            }

            // Month expiry
            if ('cardExpiryMonth' in settings) {
                var cardExpiryMonth = eziDebit.getElement(settings.cardExpiryMonth).value;

                if (!parseInt(cardExpiryMonth, 10)) {
                    eziDebitErrorMessages.generateError("Month expiry must be a number", settings.submitError, settings.cardExpiryMonth);
                    eziDebit.set(false);
                    return false;
                }

                if (cardExpiryMonth.length < 1) {
                    eziDebitErrorMessages.generateError("Must enter card month expiry", settings.submitError, settings.cardExpiryMonth);
                    eziDebit.set(false);
                    return false;
                }

                if (cardExpiryMonth > 12 || cardExpiryMonth < 1) {
                    eziDebitErrorMessages.generateError("The card month expiry must be between 1 and 12", settings.submitError, settings.cardExpiryMonth);
                    eziDebit.set(false);
                    return false;
                }

                var currentYear = new Date().getFullYear();
                var currentMonth = new Date().getMonth() + 1;
                var cardExpiryYear = eziDebit.getElement(settings.cardExpiryYear).value;

                if (cardExpiryYear == currentYear) {
                    if (cardExpiryMonth < currentMonth) {
                        eziDebitErrorMessages.generateError("The card expiry is in the past", settings.submitError, settings.cardExpiryMonth);
                        eziDebit.set(false);
                        return false;
                    }
                }
            }
            else {
                eziDebitErrorMessages.generateError("Card month expiry field not found", settings.submitError);
                eziDebit.set(false);
                return false;
            }

            // Year expiry
            if ('cardExpiryYear' in settings) {
                var cardExpiryYear = eziDebit.getElement(settings.cardExpiryYear).value;

                if (!parseInt(cardExpiryYear, 10)) {
                    eziDebitErrorMessages.generateError("Year expiry must be a 4 digit number", settings.submitError, settings.cardExpiryYear);
                    eziDebit.set(false);
                    return false;
                }

                if (cardExpiryYear.length != 4) {
                    eziDebitErrorMessages.generateError("Year expiry must be a 4 digit number", settings.submitError, settings.cardExpiryYear);
                    eziDebit.set(false);
                    return false;
                }

                var currentYear = new Date().getFullYear();

                if (cardExpiryYear < currentYear) {
                    eziDebitErrorMessages.generateError("The card year expiry is in the past", settings.submitError, settings.cardExpiryYear);
                    eziDebit.set(false);
                    return false;
                }

                if (cardExpiryYear > currentYear + 20) {
                    eziDebitErrorMessages.generateError("The card year expiry is more than 20 years in the future", settings.submitError, settings.cardExpiryYear);
                    eziDebit.set(false);
                    return false;
                }
            }
            else {
                eziDebitErrorMessages.generateError("Card month expiry field not found", settings.submitError);
                eziDebit.set(false);
                return false;
            }

            var paymentAmount = null;
            if ('paymentAmount' in settings) {
                paymentAmount = eziDebit.getElement(settings.paymentAmount).value;
            }
            var paymentReference = null;
            if ('paymentReference' in settings) {
                var paymentReference = eziDebit.getElement(settings.paymentReference).value;
            }

            //paymentAmount or paymentReference provided
            mustCharge = mustCharge || ('paymentAmount' in settings && paymentAmount.length >= 1 && parseFloat(paymentAmount) != 0) ||
                ('paymentReference' in settings && paymentReference.length >= 1);
            if (mustCharge) {

                // CCV number
                if ('cardCCV' in settings) {
                    var ccvNumber = eziDebit.getElement(settings.cardCCV).value;
                    var cardNumber = eziDebit.getElement(settings.cardNumber).value;

                    // Don't check this if the card is a Diners
                    if (cardNumber.length != 14) {

                        if (!parseInt(ccvNumber, 10)) {
                            eziDebitErrorMessages.generateError("CCV must be a 3 or 4 digit number", settings.submitError, settings.cardCCV);
                            eziDebit.set(false);
                            return false;
                        }

                        if (ccvNumber.length < 1 || ccvNumber.length < 3 || ccvNumber.length > 4) {
                            eziDebitErrorMessages.generateError("The CCV number should be either 3 or 4 digits long", settings.submitError, settings.cardCCV);
                            eziDebit.set(false);
                            return false;
                        }
                    }
                }
                else {
                    eziDebitErrorMessages.generateError("Card CCV field not found", settings.submitError);
                    eziDebit.set(false);
                    return false;
                }
                // Payment reference
                if ('paymentReference' in settings) {
                    if (paymentReference.length < 1) {
                        eziDebitErrorMessages.generateError("No payment reference entered", settings.submitError, settings.paymentReference);
                        eziDebit.set(false);
                        return false;
                    }
                }
                else {
                    eziDebitErrorMessages.generateError("Payment reference field not found", settings.submitError);
                    eziDebit.set(false);
                    return false;
                }


                // Payment amount
                if ('paymentAmount' in settings) {
                    if (paymentAmount.length < 1) {
                        eziDebitErrorMessages.generateError("No payment amount entered", settings.submitError, settings.paymentAmount);
                        eziDebit.set(false);
                        return false;
                    }

                    if (!parseFloat(paymentAmount)) {
                        eziDebitErrorMessages.generateError("Payment amount invalid", settings.submitError, settings.paymentAmount);
                        eziDebit.set(false);
                        return false;
                    }
                }
                else {
                    eziDebitErrorMessages.generateError("Payment amount field not found", settings.submitError);
                    eziDebit.set(false);
                    return false;
                }
            }
            return true;
        },

        entryIsNumber = function (evt) {
            var charCode = (evt.which) ? evt.which : event.keyCode
            if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
                return false;

            return true;
        };

    return {
        validate: performValidation,
        validateNumber: entryIsNumber
    };

} ();

// Card number validator
var eziDebitCCValidator = function () {

    validate = function (ccNumb) {
        var valid = "0123456789"  // Valid digits in a credit card number
        var len = ccNumb.length;  // The length of the submitted cc number
        var iCCN = parseInt(ccNumb);  // integer of ccNumb
        var sCCN = ccNumb.toString();  // string of ccNumb
        sCCN = sCCN.replace(/^\s+|\s+$/g, '');  // strip spaces
        var iTotal = 0;  // integer total set at zero
        var bNum = true;  // by default assume it is a number
        var bResult = false;  // by default assume it is NOT a valid cc
        var temp;  // temp variable for parsing string
        var calc;  // used for calculation of each digit

        // Determine if the ccNumb is in fact all numbers
        for (var j = 0; j < len; j++) {
            temp = "" + sCCN.substring(j, j + 1);
            if (valid.indexOf(temp) == "-1") { bNum = false; }
        }

        // if it is NOT a number, you can either alert to the fact, or just pass a failure
        if (!bNum) {
            /*alert("Not a Number");*/bResult = false;
        }

        // Determine if it is the proper length
        if ((len == 0) && (bResult)) {  // nothing, field is blank AND passed above # check
            bResult = false;
        } else {  // ccNumb is a number and the proper length - let's see if it is a valid card number
            if (len >= 15) {  // 15 or 16 for Amex or V/MC
                for (var i = len; i > 0; i--) {  // LOOP throught the digits of the card
                    calc = parseInt(iCCN) % 10;  // right most digit
                    calc = parseInt(calc);  // assure it is an integer
                    iTotal += calc;  // running total of the card number as we loop - Do Nothing to first digit
                    i--;  // decrement the count - move to the next digit in the card
                    iCCN = iCCN / 10;                               // subtracts right most digit from ccNumb
                    calc = parseInt(iCCN) % 10;    // NEXT right most digit
                    calc = calc * 2;                                 // multiply the digit by two
                    // Instead of some screwy method of converting 16 to a string and then parsing 1 and 6 and then adding them to make 7,
                    // I use a simple switch statement to change the value of calc2 to 7 if 16 is the multiple.
                    switch (calc) {
                        case 10: calc = 1; break;       //5*2=10 & 1+0 = 1
                        case 12: calc = 3; break;       //6*2=12 & 1+2 = 3
                        case 14: calc = 5; break;       //7*2=14 & 1+4 = 5
                        case 16: calc = 7; break;       //8*2=16 & 1+6 = 7
                        case 18: calc = 9; break;       //9*2=18 & 1+8 = 9
                        default: calc = calc;           //4*2= 8 &   8 = 8  -same for all lower numbers
                    }
                    iCCN = iCCN / 10;  // subtracts right most digit from ccNum
                    iTotal += calc;  // running total of the card number as we loop
                }  // END OF LOOP
                if ((iTotal % 10) == 0) {  // check to see if the sum Mod 10 is zero
                    bResult = true;  // This IS (or could be) a valid credit card number.
                } else {
                    bResult = false;  // This could NOT be a valid credit card number
                }
            }
        }
        // change alert to on-page display or other indication as needed.
        return bResult; // Return the results
    };

    return {
        validateCard: validate
    };

} ();

/*
 * Lightweight JSONP fetcher
 * Copyright 2010-2012 Erik Karlsson. All rights reserved.
 * BSD licensed
 */
var JSONP = (function () {
    var counter = 0, head, query, key, window = this, config = {};
    function load(url, failureCallback) {
        var script = document.createElement('script'),
            done = false;
        script.src = url;
        script.async = true;

        var timeout = setTimeout(function () {
            if (!done && failureCallback) {
                failureCallback();
            }
        }, config['timeout'] || 10000);

        script.onload = script.onreadystatechange = function () {
            if (!done && (!this.readyState || this.readyState === "loaded" || this.readyState === "complete")) {
                done = true;
                script.onload = script.onreadystatechange = null;
                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            }
        };
        if (!head) {
            head = document.getElementsByTagName('head')[0];
        }
        head.appendChild(script);
    }
    function encode(str) {
        return encodeURIComponent(str);
    }
    function jsonp(url, params, callback, failureCallback, callbackName) {
        query = (url || '').indexOf('?') === -1 ? '?' : '&';
        params = params || {};
        for (key in params) {
            if (params.hasOwnProperty(key)) {
                query += encode(key) + "=" + encode(params[key]) + "&";
            }
        }
        var jsonp = "json" + (++counter);
        window[jsonp] = function (data) {
            callback(data);
            try {
                delete window[jsonp];
            } catch (e) { }
            window[jsonp] = null;
        };

        load(url + query + (callbackName || config['callbackName'] || 'callback') + '=' + jsonp, failureCallback);
        return jsonp;
    }
    function setDefaults(obj) {
        config = obj;
    }
    return {
        get: jsonp,
        init: setDefaults
    };
} ());